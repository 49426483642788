import { FaRedoAlt } from 'react-icons/fa'
import { Control } from './Control'

export const Redo: Control = {
  id: 'redo',
  formats: [],
  tooltip: 'Redo',
  renderer: (quill: any) => (
    <button
      onClick={() => {
        quill.history.redo()
      }}
    >
      <FaRedoAlt />
    </button>
  )
}
