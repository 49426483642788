import 'simplebar-react/dist/simplebar.min.css'
import { Flex, Text } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { FiCheckCircle, FiXCircle } from 'react-icons/fi'
import { globalStyles } from 'theme/styles'
import { Card } from '../Card'

export enum MessageVariant {
  Error = 'error',
  Success = 'success'
}

export type DialogMessage = {
  message: string
  variant: MessageVariant
}
interface DialogBoxProps {
  showFeedback?: boolean
  autoClose?: boolean
  message: DialogMessage
  props?: any
  onClose: () => void
}

export const DialogBox = ({ message, autoClose = true, onClose, props }: DialogBoxProps) => {
  const [isVisible, setIsVisible] = useState(!!message)
  const [isFadingOut, setIsFadingOut] = useState(false)
  const color =
    message?.variant === MessageVariant.Error ? globalStyles.colors.red[500] : globalStyles.colors.green[500]
  const Icon = () =>
    message?.variant === MessageVariant.Error ? <FiXCircle color={color} /> : <FiCheckCircle color={color} />

  useEffect(() => {
    if (message) {
      setIsVisible(true)
      setIsFadingOut(false)

      if (!autoClose) {
        return
      }

      const timer = setTimeout(() => {
        setIsFadingOut(true)
      }, 3000)

      return () => clearTimeout(timer)
    } else {
      setIsVisible(false)
    }
  }, [message, autoClose])

  useEffect(() => {
    if (isFadingOut && autoClose) {
      const fadeOutTimer = setTimeout(onClose, 400)

      return () => clearTimeout(fadeOutTimer)
    }
  }, [isFadingOut, autoClose, onClose])

  return (
    <Flex
      style={{
        alignItems: 'center',
        justifyContent: 'center',
        paddingTop: '20px',
        fontFamily: 'Nunito Sans',
        fontSize: '20px',
        zIndex: 1000,
        fontWeight: '700',
        transition: 'opacity 0.3s ease-out, transform 0.3s ease-out',
        transform: isVisible && !isFadingOut ? 'translateY(0)' : 'translateY(-200%)',
        opacity: isVisible && !isFadingOut ? 1 : 0
      }}
    >
      <Card
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '20px',
          width: '60%',
          minWidth: '300px',
          background: 'white',
          border: `3px solid ${color}`,
          padding: '5px 10px',
          ...props
        }}
      >
        <Icon />
        <Text>{message?.message}</Text>
      </Card>
    </Flex>
  )
}
