import 'simplebar-react/dist/simplebar.min.css'
import { Flex, useColorModeValue } from '@chakra-ui/react'

export const Card = ({ children, ...rest }) => {
  return (
    <Flex
      alignItems={'center'}
      justifyContent={'center'}
      borderRadius="lg"
      boxShadow={useColorModeValue('sm', 'sm-dark')}
      backgroundColor={'white'}
      px={{ base: '4', md: '40px' }}
      py={{ base: '5', md: '48px' }}
      {...rest}
    >
      {children}
    </Flex>
  )
}
