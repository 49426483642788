import { Role } from './Role'

export interface SelectItem {
  value: any
  name: string
}

export interface SimpleFieldProps {
  label?: string
  name: string
  helper?: string
}

export interface FieldProps extends SimpleFieldProps {
  isRequired?: boolean
  placeholder?: string
}

export interface SelectProps extends FieldProps {
  items: SelectItem[]
}

export interface InputProps extends FieldProps {}

export interface SwitchProps extends SimpleFieldProps {}

export interface TagsInputProps extends FieldProps {
  items: SelectItem[]
  onSearch: (string) => void
}

export interface DistrictsInputProps extends FieldProps {
  onSearch: (string) => void
}

export interface SearchInputProps {
  term: string
  items: SelectItem[]
  onChange: (string) => void
  onSelected: (ItemType) => void
  placeholder: string
  style: any
}

export interface NavigationState {
  forwardEnabled: boolean
  backEnabled: boolean
}

export function toRoleName(role: number) {
  switch (role) {
    case Role.TUTOR:
      return 'Tutor'
    case Role.ADMIN:
      return 'Admin'
    case Role.SCHOOL_STUDENT:
      return 'School Student'
    case Role.CONTENT_ADMIN:
      return 'Content Admin'
    default:
      return 'unknown'
  }
}
