import { Icon } from '@chakra-ui/react'

const SentenceEnumerationClearIcon = (props) => {
  return (
    <Icon viewBox="0 0 37.042 37.04" {...props}>
      <g fill="currentColor" font-family="sans-serif">
        <text transform="scale(1.0689 .93557)" x="2.5284119" y="35.807499" font-size="36.593px" stroke-width=".91483">
          <tspan x="2.5284119" y="35.807499" stroke-width=".91483">
            S
          </tspan>
        </text>
        <path d="m27.007 2.695 7.7983 7.2249" stroke="currentColor" stroke-width="2" />
        <path d="m27.065 9.7479 7.9703-7.2249" stroke="currentColor" stroke-width="2" />
      </g>
    </Icon>
  )
}

export default SentenceEnumerationClearIcon
