import FieldSmallIcon from 'components/design-system/bits/field-small-icon'
import { Control } from './Control'

export const SmallField: Control = {
  id: 'field-small',
  formats: ['math-formula'],
  tooltip: 'Small field',
  icon: <FieldSmallIcon />,
  onClick: (quill: any) => {
    const range = quill.getSelection(true)

    quill.deleteText(range.index, range.length)
    quill.insertEmbed(range.index, 'math-formula', '\\rule{4mm}{1pt}')
    quill.setSelection(range.index + range.length + 1)
  }
}
