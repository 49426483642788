import SentenceEnumerationClearIcon from 'components/design-system/bits/sentence-enumeration-clear-icon'
import SentenceEnumerationIcon from 'components/design-system/bits/sentence-enumeration-icon'
import { Control } from './Control'

const isEnumerationOperation = (operation) => operation?.attributes?.enumeration === true

const clearSenteceEnumeration = (quill: any) => {
  const contents = quill.getContents()
  let deltaOperations = contents.ops

  if (deltaOperations.length > 0) {
    deltaOperations = deltaOperations.filter((operation) => !isEnumerationOperation(operation))
    quill.setContents(deltaOperations)
  }
}

const enumerateSentences = (quill: any) => {
  clearSenteceEnumeration(quill)

  const contents = quill.getContents()
  const deltaOperations = contents.ops

  if (deltaOperations.length > 0) {
    let enumeratorIndex = 1
    let operationIndex = 0
    let isSentenceBeginning = true

    while (operationIndex < deltaOperations.length) {
      const currentOperation = deltaOperations[operationIndex]

      if (isSentenceBeginning) {
        deltaOperations.splice(operationIndex, 0, {
          insert: `${enumeratorIndex++}`,
          attributes: { enumeration: true }
        })

        isSentenceBeginning = false
      } else if (typeof currentOperation.insert === 'string') {
        // The regular expression to split sentences was obtained from the correct answer from
        // this post => https://stackoverflow.com/questions/25735644/python-regex-for-splitting-text-into-sentences-sentence-tokenizing
        const matchResults = currentOperation.insert.match(/(?<!\w\.\w.)(?<![A-Z][a-z]\.)(?<=\.|\?|!)\s+\S/)

        if (matchResults) {
          const matchText = matchResults[0]
          const matchIndex = currentOperation.insert.indexOf(matchText) + matchText.length - 1

          if (matchIndex >= 0) {
            deltaOperations.splice(
              operationIndex,
              1,
              {
                ...currentOperation,
                insert: currentOperation.insert.substring(0, matchIndex)
              },
              {
                ...currentOperation,
                insert: currentOperation.insert.substring(matchIndex)
              }
            )

            isSentenceBeginning = true
          }
        }
      }

      operationIndex++
    }
  }

  quill.setContents(deltaOperations)
}

export const SentenceEnumerator: Control = {
  id: 'sentenceEnumerator',
  formats: ['enumeration'],
  tooltip: 'Enumerate sentences',
  icon: <SentenceEnumerationIcon />,
  onClick: enumerateSentences
}

export const SentenceEnumeratorClear: Control = {
  id: 'sentenceEnumeratorClear',
  formats: ['enumeration'],
  tooltip: 'Clear sentence enumerations',
  icon: <SentenceEnumerationClearIcon />,
  onClick: clearSenteceEnumeration
}
