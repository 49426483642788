import FieldLargeIcon from 'components/design-system/bits/field-large-icon'
import { Control } from './Control'

export const LargeField: Control = {
  id: 'field-large',
  formats: ['math-formula'],
  tooltip: 'Large field',
  icon: <FieldLargeIcon />,
  onClick: (quill: any) => {
    const range = quill.getSelection(true)

    quill.deleteText(range.index, range.length)
    quill.insertEmbed(range.index, 'math-formula', '\\rule{12mm}{1pt}')
    quill.setSelection(range.index + range.length + 1)
  }
}
