import { Icon } from '@chakra-ui/react'

const SentenceEnumerationIcon = (props) => {
  return (
    <Icon viewBox="0 0 37.042 37.04" {...props}>
      <g fill="currentColor" font-family="sans-serif">
        <text transform="scale(1.0689 .93557)" x="2.5284119" y="35.807499" font-size="36.593px" stroke-width=".91483">
          <tspan x="2.5284119" y="35.807499" stroke-width=".91483">
            S
          </tspan>
        </text>
        <text transform="scale(1.2238 .81714)" x="19.865492" y="15.656877" font-size="19.084px" stroke-width=".4771">
          <tspan x="19.865492" y="15.656877" stroke-width=".4771">
            1
          </tspan>
        </text>
      </g>
    </Icon>
  )
}

export default SentenceEnumerationIcon
