import { FaUndoAlt } from 'react-icons/fa'
import { Control } from './Control'

export const Undo: Control = {
  id: 'undo',
  formats: [],
  tooltip: 'Undo',
  renderer: (quill: any) => (
    <button
      onClick={() => {
        quill.history.undo()
      }}
    >
      <FaUndoAlt />
    </button>
  )
}
